export const IntercomWhitelistedEvents = new Set([
  'web_accept_signup_invitation',
  'web_activate_integration',
  'web_add_user',
  'web_additional_data_field_create',
  'web_additional_data_group_create',
  'web_bundles_copy',
  'web_category_create',
  'web_category_delete',
  'web_create_project',
  'web_download_drawing_takeoff',
  'web_download_drawing_version',
  'web_export_products',
  'web_fence_library_wizard_finish',
  'web_fence_library_wizard_start',
  'web_import_from_library_succeeded',
  'web_login',
  'web_open_advanced_settings',
  'web_open_collaborators',
  'web_open_drawing',
  'web_open_drawing_takeoff',
  'web_open_profile',
  'web_open_project',
  'web_open_subscriptions',
  'web_product_create',
  'web_product_edit',
  'web_product_excel_import',
  'web_products_copy',
  'web_proposal_approved',
  'web_proposal_created',
  'web_proposal_sent',
  'web_proposal_template_create',
  'web_proposal_template_publish',
  'web_send_to_jobnimbus',
  'web_set_global_product_margin',
  'web_signup',
  'web_signup_complete_step1',
  'web_signup_invite_teammates',
  'web_bundles_copy',
  'web_bundle_create',
]);
