export const userAgent =
  typeof window === 'undefined' ? '' : navigator.userAgent;

// ArcSite UA
// example: ArcSite@1.2.3
export const ARCSITE_REG = /ArcSite@([\d.]+)?/i;

export const mobilePattern = /mobile|mobi|wap|simulator|iphone|android/gi;
export const ardVersionPattern = /Android\s+([\d.]+);/i;
export const iosPattern = /(iphone|ipod|ipad)/i;
