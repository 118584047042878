import { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function Intercom({ children }: { children: ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('intercom')) {
      window.Intercom('show');
      window.Intercom('onHide', () => {
        searchParams.delete('intercom');
        setSearchParams(searchParams);
      });
    }
  }, [searchParams]);
  return <>{children}</>;
}
