import { combineReducers } from 'redux';
import prodActions from '../../actions/product-management/products';
import { cloneDeep } from 'lodash-es';

const copyingProducts = (state = {}, action: any) => {
  switch (action.type) {
    case prodActions.REQUEST_COPY_PRODUCTS:
      // @ts-ignore
      if (state && state.copying) {
        throw 'Product copying is working!';
      }

      return Object.assign({}, state, {
        ids: action.payload.product_ids,
        categoryId: action.payload.to_category_id,
        copying: true,
        success: false,
      });
    case prodActions.RECEIVE_COPY_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        copying: false,
        success: true,
      });
    case prodActions.RECEIVE_COPY_PRODUCTS_ERROR:
      return Object.assign({}, state, {
        copying: false,
        success: false,
      });
  }
  return cloneDeep(state);
};

const ui = combineReducers({
  copyingProducts,
});

export default ui;
