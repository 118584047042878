import * as React from 'react';

type Props = {
  href?: string;
  target?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
};

export default function A({
  href,
  children,
  target,
  style = {},
  ...rest
}: Props) {
  return (
    <a
      target={target}
      style={{
        color: '#1b98e0',
        textDecoration: 'underline',
        ...style,
      }}
      href={href}
      {...rest}
    >
      {children}
    </a>
  );
}
