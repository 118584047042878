export const calculateRemainingTrialDays = (expireTime: number) =>
  Math.floor((expireTime - Date.now() / 1000) / 3600 / 24);

export const isBetweenDate = (startDate: string, endDate: string): boolean => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  return now >= start && now <= end;
};
