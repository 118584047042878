import {
  ARCSITE_REG,
  ardVersionPattern,
  iosPattern,
  mobilePattern,
} from './consts';

export function isMobile() {
  return mobilePattern.test(navigator.userAgent) || isIpadOS();
}

export function isAndroid() {
  return ardVersionPattern.test(navigator.userAgent);
}

export function isIos() {
  return iosPattern.test(navigator.userAgent);
}

export function isIpadOS() {
  return (
    Boolean(
      navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
    ) || /iPad/.test(navigator.platform)
  );
}

/**
 * 格式化版本号
 * 用于版本比较
 * 如'3.5.3'格式化为3000005000003
 */
export function formatAppver(version: string) {
  function pad(_num: string) {
    return _num + Array(6 - `${_num}`.length + 1).join('0');
  }
  let _arr = version.split('.');
  let _last = _arr.pop();
  let _s = '';
  for (let i = 0, l = _arr.length; i < l; i++) {
    _s += pad(_arr[i]);
  }
  return parseInt(_s + _last, 10);
}

const userAgent = window.navigator.userAgent;

export function getAppInfo() {
  const matcher = userAgent.match(ARCSITE_REG);
  if (matcher) {
    const version = matcher[1];
    return {
      isInApp: true,
      version,
    };
  }
  return {
    isInApp: false,
    version: null,
  };
}

export function isInApp() {
  return getAppInfo().isInApp;
}
