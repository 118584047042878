import { fetchUser } from '@/api/user';
import { callPostApi } from '@/components/api-common';

const signUp = (params: any) => {
  return callPostApi('/user/service/userAuth/signUp/v2/', params) as Promise<{
    isSignedUp: boolean;
    code: number;
  }>;
};

const inviteUser = (params: { org_name: number; emails: Array<string> }) => {
  return callPostApi(
    '/user/service/userAuth/convert_to_company_and_invte_members/',
    params
  );
};

const resetPwd = (params: {
  k?: string;
  user_id: string;
  password: string;
}) => {
  return callPostApi('/user/service/userAuth/resetpwd/', params);
};

const verifyResetCode = (params: { k?: string }) => {
  return callPostApi(
    '/user/service/userAuth/verify_pwd_code/',
    params
  ) as Promise<{
    user_id: string;
    username: string;
  }>;
};

const checkLogin = () => {
  return fetchUser();
};

const login = (params: { username: string; password: string }) => {
  return callPostApi('/user/service/userAuth/login/v2/', params) as Promise<{
    isLoggedIn: boolean;
    errorMessage: string;
  }>;
};

const forgetpwd = (params: { username: string }) => {
  return callPostApi('/user/service/userAuth/forgetpwd/', params);
};

export const getEverLoginApp = () => {
  return callPostApi(
    '/user/service/userAuth/if_user_ever_login_app/'
  ) as Promise<{ value: boolean }>;
};

export const getCheckUserNameAvailable = (username: string) => {
  return callPostApi('/user/service/userAuth/if_username_available/', {
    username,
  }) as Promise<{ available: boolean }>;
};

type SignupCategory = {
  signup_industry_category: string;
  industries: string[];
};

export const getSignupCategories = () => {
  return callPostApi('/user/service/signup_industries/') as Promise<
    SignupCategory[]
  >;
};

type SignupCategory2 = {
  signup_industry_category: { name: string; display_name: string };
  industries: { name: string; display_name: string }[];
};

export const getSignupCategoriesV2 = () => {
  return callPostApi('/user/service/signup_industries/v2/') as Promise<
    SignupCategory2[]
  >;
};

export default {
  signUp,
  inviteUser,
  resetPwd,
  verifyResetCode,
  checkLogin,
  login,
  forgetpwd,
  getCheckUserNameAvailable,
  getSignupCategories,
  getSignupCategoriesV2,
};
