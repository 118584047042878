import { FC } from 'react';
import { Spin, SpinProps } from 'antd';

type LoadingProps = SpinProps & {
  containerClassName?: string;
};

const Loading: FC<LoadingProps> = ({ containerClassName = '', tip = '' }) => {
  return (
    <div
      className={`h-[200px] flex justify-center items-center ${containerClassName}`}
    >
      <Spin tip={tip} />
    </div>
  );
};

export default Loading;
