export function includes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T);
}

export function bulkMoveArray<T>(
  originalArray: Array<T>,
  itemsToBeMoved: Array<T>,
  newIndexToBeMoved: number,
  after = true
): Array<T> {
  // find the value of the element the marks the insertion point
  let insertBefore = originalArray[newIndexToBeMoved];

  // in original sequence order, check for presence in the removal
  // list, *and* remove them from the original array
  let moved = [];
  for (let i = 0; i < originalArray.length; ) {
    let value = originalArray[i];
    if (itemsToBeMoved.indexOf(value) >= 0) {
      moved.push(value);
      originalArray.splice(i, 1);
    } else {
      ++i;
    }
  }

  // find the new index of the insertion point
  let insertionIndex = originalArray.indexOf(insertBefore);
  if (insertionIndex < 0) {
    insertionIndex = originalArray.length;
  }

  // and add the elements back in
  originalArray.splice(
    after ? insertionIndex + 1 : insertionIndex,
    0,
    ...moved
  );

  return [...originalArray];
}
