import { callPostApi } from '@/components/api-common';

const OPEN_DRAWER = 'OPEN_DRAWER';

const REQUEST_COPY_PRODUCTS = 'REQUEST_COPY_PRODUCTS';
const RECEIVE_COPY_PRODUCTS_SUCCESS = 'RECEIVE_COPY_PRODUCTS_SUCCESS';
const RECEIVE_COPY_PRODUCTS_ERROR = 'RECEIVE_COPY_PRODUCTS_ERROR';

const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';

// @ts-expect-error
function copyProductsToCategory(params) {
  return callPostApi('/user/service/products/copy/', params);
}

const copyProducts = (params: any, request: boolean) => {
  return (dispatch: any) => {
    if (request) {
      dispatch({
        type: REQUEST_COPY_PRODUCTS,
        payload: params,
      });
    }
    return copyProductsToCategory(params)
      .then(res => {
        dispatch({
          type: RECEIVE_COPY_PRODUCTS_SUCCESS,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: RECEIVE_COPY_PRODUCTS_ERROR,
          error: error,
        });
        return Promise.reject(error);
      });
  };
};

// const setActiveCategory = (category) => {
//     return (dispatch, getState) => {
//         const state = getState();
//         dispatch({
//             type: SET_ACTIVE_CATEGORY,
//             payload: category,
//         });

//         return api.getProductsByCategoryUuid()
//     }
// }

export default {
  copyProducts,
  REQUEST_COPY_PRODUCTS,
  RECEIVE_COPY_PRODUCTS_SUCCESS,
  RECEIVE_COPY_PRODUCTS_ERROR,

  SET_ACTIVE_CATEGORY,
  OPEN_DRAWER,
};
