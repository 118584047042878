import type { User } from '@/reducers/product-management';

export function showAdmin(user: User | null) {
  if (!user) {
    return false;
  }
  if (user.default_org_type == 'INDIVIDUAL') {
    return true;
  }
  return user.roles.includes('MANAGE_SETTINGS');
}
