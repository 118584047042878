import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    const mainEle = document.getElementById('arcsite-main');
    if (!mainEle) return;
    mainEle.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
