import { createStore, applyMiddleware, compose, PreloadedState } from 'redux';
import thunk from 'redux-thunk';
import { message } from 'antd';
// @ts-ignore
import { downloadResource } from './misc/download';
import reducer, { initialTasks } from './reducers/product-management';
// @ts-ignore
import { callPostApi } from './components/api-common';

const middlewares = [thunk];
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export type RootState = ReturnType<typeof store.getState>;

export function createAppStore(initialState?: PreloadedState<RootState>) {
  return createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

// @ts-expect-error
function queryTaskStatus(params) {
  return callPostApi('/user/service/my/project/task_status/', params);
}

for (const task of initialTasks) {
  const taskId = task.taskId;
  if (!taskId) {
    continue;
  }
  /* eslint-disable-next-line no-var */
  var poll = setInterval(() => {
    queryTaskStatus({
      task_id: taskId,
    })
      .then((data: any) => {
        if (localStorage.getItem('archiving-tasks') == null) {
          clearInterval(poll);
          store.dispatch({ type: 'CLEAR_TASK' });
          return;
        }
        if (data.state == 'PROGRESS') {
          store.dispatch({
            type: 'UPDATE_TASK',
            taskId,
            progress: data.progress,
          });
          const tasks = store.getState().archiveTasks;
          localStorage.setItem('archiving-tasks', JSON.stringify(tasks));
        }
        if (data.state == 'SUCCESS') {
          downloadResource(data.result);
          store.dispatch({ type: 'FINISH_TASK', taskId });
          const tasks = store.getState().archiveTasks;
          localStorage.setItem('archiving-tasks', JSON.stringify(tasks));
          clearInterval(poll);
        }
        if (data.state == 'FAILURE') {
          store.dispatch({ type: 'FINISH_TASK', taskId });
          const tasks = store.getState().archiveTasks;
          localStorage.setItem('archiving-tasks', JSON.stringify(tasks));
          clearInterval(poll);
          message.error('download failed');
        }
      })
      .catch((res: any) => {
        // not login
        if (res.code == 115) {
          clearInterval(poll);
        }
      });
  }, 2000);
}

export default store;
