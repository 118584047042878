import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';

import { useUser } from '@/hooks';
import { showAdmin } from '@/utils/user';

interface AdminGuardProps {
  redirectTo?: string;
  children: React.ReactNode;
}

const AdminGuard: React.FC<AdminGuardProps> = ({
  redirectTo = '/app/projects',
  children,
}) => {
  const user = useUser();
  const isAdmin = showAdmin(user);

  if (!isAdmin) return <Navigate to={redirectTo} replace={true} />;

  return <Fragment>{children}</Fragment>;
};

export default AdminGuard;
