import type { User } from '@/reducers/product-management';
import type { SubscripionInfo } from './context';
import type { Subscription } from './models';
import getBillingSubscriptions from './getBillingSubscriptions';

export function shouldFetchSubscription(user: User | null) {
  if (!user) {
    return false;
  }
  if (user.default_org_type == 'INDIVIDUAL') {
    return true;
  }
  return (
    user.default_org_type == 'COMPANY' && (user.is_admin || user.is_org_owner)
  );
}

export async function getBillingSubscription(): Promise<
  SubscripionInfo & { isEmpty: boolean }
> {
  const result = await getBillingSubscriptions();
  return extractSubscription(result);
}

export function extractSubscription(result: Subscription[]) {
  if (!result?.length) {
    // 已过期
    return { current: null, next: null, isEmpty: true };
  }

  if (result.length == 1) {
    let next = null;
    const sub = result[0];
    const schedule = sub.schedule;
    if (schedule) {
      const lastPhase = schedule.phases[schedule.phases.length - 1];
      if (lastPhase.period.start > schedule.current_phase_period.start) {
        next = lastPhase.plans[0];
      }
    }
    return {
      current: result[0],
      next,
      isEmpty: false,
    };
  }
  throw 'subscription number must be 0 or 1';
}

export function intervalText(interval: 'month' | 'year') {
  return interval == 'month' ? 'Monthly' : 'Yearly';
}

export const DEVICES = {
  Free: '(Android & Windows)',
  'Draw Basic': '(iPhone, iPad, Android & Windows)',
  'Draw Pro': '(iPhone, iPad, Android & Windows)',
  Essential: '(iPhone, iPad, Android & Windows)',
  Advanced: '(iPhone, iPad, Android & Windows)',
  'Takeoff Basic': '(iPad, Android Tablet & Windows)',
  'Estimate Pro': '(iPad, Android Tablet & Windows)',
} as const;
