import { Modal } from 'antd';
import DownloadApp from '../download-app';

const DownloadModal = (props: {
  downloadVisiable: boolean;
  setDownloadVisiable: CallBack;
}) => {
  return (
    <Modal
      title={null}
      open={props.downloadVisiable}
      onCancel={() => props.setDownloadVisiable(false)}
      footer={null}
      width={612}
      bodyStyle={{ padding: 6 }}
    >
      <div className="font-semibold text-16 text-center mt-12">
        Download the ArcSite App
      </div>
      <DownloadApp></DownloadApp>
      {/* <div className="flex mt-12 text-center text-black-55">
        <div className="flex-1 border-r-1 border-solid border-black-10 pr-4">
          <div className="mb-4">Scan the QR code with your iPad or iPhone:</div>
          <img alt="qrCode" className="w-[120px]" src={ArcSiteAppStoreQRCode} />
        </div>
        <div className="flex-1">
          <div className="mb-4 py-3">Or click this link:</div>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/arcsite-graphic-notebook-for/id986274256"
            rel="noreferrer"
          >
            <img alt="app store" className="h-8" src={AppStoreLogo} />
          </a>
        </div>
      </div> */}
    </Modal>
  );
};

export default DownloadModal;
