import React, { createContext, useEffect, useState } from 'react';
import api from './components/takeoff/api';
import { defaultCurrencySettings } from './reducers/product-management';

export type Currency = (typeof defaultCurrencySettings)['currency'];
type CurrencyContextValue = {
  currency: Currency | null;
  setCurrency: React.Dispatch<React.SetStateAction<Currency | null>>;
};

const CurrencyContext = createContext<CurrencyContextValue | null>(null);

export function CurrencyProvider({ children }: { children: React.ReactNode }) {
  const [currency, setCurrency] = useState<Currency | null>(null);

  useEffect(() => {
    api.queryOrgTakeoffSetting().then(res => {
      if (res.count != 0) {
        setCurrency(res.result[0].currency);
      } else {
        setCurrency(defaultCurrencySettings['currency']);
      }
    });
  }, []);

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
}

export function useCurrency(): CurrencyContextValue {
  const currency = React.useContext(CurrencyContext);
  if (!currency) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return currency;
}
