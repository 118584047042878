import tracker from './tracker';
import { callPostApi } from '@/components/api-common';
import * as Sentry from '@sentry/react';
import { useStore } from 'react-redux';
import { useNavigate } from 'react-router';

export const auth = {
  isAuthenticated: document.cookie.indexOf('arcuser_auth') >= 0,
  async authenticate() {
    this.isAuthenticated = true;
  },
  async signout() {
    this.isAuthenticated = false;
  },
};

export function useLogout() {
  const store = useStore();

  const navigate = useNavigate();
  function logout(redirectUrl?: string) {
    tracker.logEvent('web_logout');

    return callPostApi('user/service/userAuth/logout/v2/').then(() => {
      store.dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('archiving-tasks');
      localStorage.removeItem('project-preview');
      tracker.onLogout();
      Sentry.setUser(null);
      auth.signout();
      navigate(redirectUrl || '/login', { replace: true });
    });
  }
  return logout;
}
