import { useUser } from '@/hooks';
import { showAdmin } from '@/utils/user';
import { useMemo } from 'react';
import { matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type ArcSiteMenu = {
  label: string;
  link: string;
  include: string[];
  exclude?: string[];
};

const NORMAL_MENUS: ArcSiteMenu[] = [
  {
    label: 'Projects',
    link: '/app/projects',
    include: [
      '/app/projects',
      '/app/project/:id',
      '/app/project/proposal-list/:projectId',
      '/app/project/proposal-preview/:proposalId',
      '/app/drawing/:projectId/:drawingId/:versionId',
      '/app/drawing/:projectId/:drawingId',
      '/app/project/create-proposal/:projectId/',
    ],
  },
];

const DASHBOARD: ArcSiteMenu[] = [
  {
    label: 'Dashboard',
    link: '/app/dashboard',
    include: ['/app/dashboard', '/app/dashboard/detail'],
  },
];

const ADMIN_MENUS: ArcSiteMenu[] = [
  {
    label: 'Proposals',
    link: '/app/proposals',
    include: [
      '/app/proposals',
      '/app/proposal/detail/:proposalId',
      '/app/proposal/detail/v2/:proposalId',
    ],
  },
  {
    label: 'Products',
    link: '/app/products',
    include: ['/app/products', '/admin/product_management'],
  },
  {
    label: 'Settings',
    link: '/admin',
    include: ['/admin/*'],
    exclude: ['/admin/product_management'],
  },
];

export function GlobalNav() {
  const loc = useLocation();
  const user = useUser();
  const isAdmin = showAdmin(user);
  const { pathname } = loc;

  const menus = isAdmin
    ? [...DASHBOARD, ...NORMAL_MENUS, ...ADMIN_MENUS]
    : NORMAL_MENUS;

  const activeIndex = useMemo(() => {
    for (let index = 0; index < menus.length; index++) {
      const menu = menus[index];
      for (let j = 0; j < menu.include.length; j++) {
        const matchInclude = matchPath(
          {
            path: menu.include[j],
          },
          pathname
        );
        if (matchInclude && !menu.exclude) return index;

        if (matchInclude) {
          const notMatchExclude = menu.exclude?.every(
            ex =>
              !matchPath(
                {
                  path: ex,
                },
                pathname
              )
          );
          if (notMatchExclude) return index;
        }
      }
    }
    return -1;
  }, [pathname, menus]);

  if (!user) return null;

  return (
    <ul className="list-none flex h-full mb-0 pl-0">
      {menus.map((menu, idx) => (
        <li
          className={twMerge(
            'sm:text-14 cursor-pointer border-b-1 mr-0 sm:mr-6 last:mr-0',
            idx === activeIndex ? 'border-blue-398AE7' : 'border-transparent'
          )}
          key={idx}
        >
          <Link
            key={idx}
            to={menu.link}
            className={twMerge(
              'flex px-2 items-center justify-between h-full hover:!text-black-85',
              idx === activeIndex ? 'text-black-85' : 'text-black-55'
            )}
          >
            {menu.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}
