import AppStoreLogo from '@/assets/images/app-store-logo.svg';
import GoogleStoreLogo from '@/assets/images/google-store.svg';
import MsStoreLogo from '@/assets/images/ms-store.png';
import GalaxyStoreLogo from '@/assets/images/galaxy-store.svg';
import AppStoreQrCode from '@/assets/images/qr-code-ios.webp';
import GoogleStoreQrCode from '@/assets/images/qr-code-android.webp';
import MsStoreQrCode from '@/assets/images/qr-code-windows.webp';
import QrCode from '@/assets/images/down-load-qrcode.png';
import { twMerge } from 'tailwind-merge';

const DownloadApp = () => {
  // const [selected, setSelected] = useState<keyof typeof APP_LINK_MAP>('ios');
  // const onChange = (e: RadioChangeEvent) => {
  //   setSelected(e.target.value);
  // };
  const APP_LINK_MAP = {
    ios: {
      logo: AppStoreLogo,
      link: 'https://apps.apple.com/us/app/arcsite-floor-plans-and-cad/id986274256',
      qrCode: AppStoreQrCode,
    },
    android: {
      logo: GoogleStoreLogo,
      link: 'https://play.google.com/store/apps/details?id=com.arcsite.app.android',
      qrCode: GoogleStoreQrCode,
    },
    galaxy: {
      logo: GalaxyStoreLogo,
      link: 'https://galaxy.store/arcsite',
      qrCode: GoogleStoreQrCode,
    },
    windows: {
      logo: MsStoreLogo,
      link: 'https://apps.microsoft.com/store/detail/arcsite/9N9HM6J715VP',
      qrCode: MsStoreQrCode,
    },
  };

  return (
    // <div className="mt-8 mb-8 py-8 sm:mx-12 bg-[#fafafa] w-full sm:w-[516px] flex flex-col items-center justify-center">
    //   <Radio.Group onChange={onChange} defaultValue="ios">
    //     <Radio.Button
    //       className="font-normal w-[94px] sm:w-[150px] text-center"
    //       value="ios"
    //     >
    //       iOS
    //     </Radio.Button>
    //     <Radio.Button
    //       className="font-normal w-[94px] sm:w-[150px] text-center"
    //       value="android"
    //     >
    //       Android
    //     </Radio.Button>
    //     <Radio.Button
    //       className="font-normal w-[94px] sm:w-[150px] text-center"
    //       value="windows"
    //     >
    //       Windows
    //     </Radio.Button>
    //   </Radio.Group>
    //   <div className="mt-8 flex items-center flex-col sm:flex-row sm:justify-center sm:items-start sm:w-[501px] sm:mx-auto">
    //     <div className="text-center sm:w-[210px] sm:mt-[6px]">
    //       {/* <p className="text-14 leading-18 text-black-85">Use this link:</p> */}
    //       <a
    //         className="mt-8"
    //         target="_blank"
    //         href={APP_LINK_MAP[selected].link}
    //         rel="noreferrer"
    //       >
    //         <img
    //           alt="app store"
    //           className={twMerge('h-12', selected === 'ios' ? '' : 'hidden')}
    //           src={APP_LINK_MAP.ios.logo}
    //         />
    //         <img
    //           alt="app store"
    //           className={twMerge(
    //             'h-12',
    //             selected === 'android' ? '' : 'hidden'
    //           )}
    //           src={APP_LINK_MAP.android.logo}
    //         />
    //         <img
    //           alt="app store"
    //           className={twMerge(
    //             'h-12',
    //             selected === 'windows' ? '' : 'hidden'
    //           )}
    //           src={APP_LINK_MAP.windows.logo}
    //         />
    //       </a>
    //     </div>
    //     <div className="w-[281px] h-[1px] bg-black-10 my-4 sm:my-0 sm:w-[1px] sm:h-[172px] sm:mx-[40px]"></div>
    //     <div className="flex flex-col justify-center sm:w-[210px] sm:pl-[16px]">
    //       <img
    //         alt="app store"
    //         className={twMerge(
    //           'h-120 w-120',
    //           selected === 'ios' ? '' : 'hidden'
    //         )}
    //         src={APP_LINK_MAP.ios.qrCode}
    //       />
    //       <img
    //         alt="app store"
    //         className={twMerge(
    //           'h-120 w-120',
    //           selected === 'android' ? '' : 'hidden'
    //         )}
    //         src={APP_LINK_MAP.android.qrCode}
    //       />
    //       <img
    //         alt="app store"
    //         className={twMerge(
    //           'h-120 w-120',
    //           selected === 'windows' ? '' : 'hidden'
    //         )}
    //         src={APP_LINK_MAP.windows.qrCode}
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className="mt-8 mb-8 m:mx-8 w-full flex flex-col items-center justify-center">
      <div className="flex items-center flex-col sm:flex-row sm:justify-center sm:items-start sm:w-[501px] sm:mx-auto">
        <div className="flex flex-col items-center sm:w-[210px]">
          <p className="text-14 leading-[22px] text-black-85 mb-4 sm:mb-6">
            Scan the QR code to download:
          </p>
          <img
            alt="qr-code"
            className={twMerge('h-[176px] w-[176px] rounded-[8px]')}
            src={QrCode}
          />
        </div>
        <div className="hidden sm:block w-[281px] h-[1px] bg-black-10 my-4 sm:my-0 sm:w-[1px] sm:h-[340px] sm:mx-[40px]"></div>
        <div className="hidden sm:w-[210px] sm:flex flex-col items-center">
          <p className="text-14 leading-[22px] text-black-85 mb-6">
            Download links:
          </p>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/arcsite-floor-plans-and-cad/id986274256"
            rel="noreferrer"
          >
            <img
              alt="app store"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.ios.logo}
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.arcsite.app.android"
            rel="noreferrer"
            className="mt-4"
          >
            <img
              alt="google store"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.android.logo}
            />
          </a>
          <a
            target="_blank"
            href="https://galaxy.store/arcsite"
            rel="noreferrer"
            className="mt-4"
          >
            <img
              alt="windows"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.galaxy.logo}
            />
          </a>
          <a
            target="_blank"
            href="https://apps.microsoft.com/store/detail/arcsite/9N9HM6J715VP"
            rel="noreferrer"
            className="mt-4"
          >
            <img
              alt="windows"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.windows.logo}
            />
          </a>
          <p className="text-12 leading-4 text-center text-black-55 mt-4">
            *
            <a
              href="https://www.arcsite.com/pricing"
              target="_blank"
              className="text-blue-398AE7 underline hover:underline"
              rel="noreferrer"
            >
              Pro and Premium
            </a>{' '}
            features currently require tablet devices.
          </p>
        </div>
        <div className="sm:hidden flex-col items-center mt-8">
          <p className="text-16 leading-[22px] text-black-85 mb-0 text-center">
            Links to download:
          </p>
          <div className="flex items-center justify-center flex-wrap">
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/arcsite-floor-plans-and-cad/id986274256"
              rel="noreferrer"
              className="mt-4"
            >
              <img
                alt="app store"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.ios.logo}
              />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.arcsite.app.android"
              rel="noreferrer"
              className="mt-4"
            >
              <img
                alt="google store"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.android.logo}
              />
            </a>
            <a
              target="_blank"
              href="https://galaxy.store/arcsite"
              rel="noreferrer"
              className="mt-4"
            >
              <img
                alt="windows"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.galaxy.logo}
              />
            </a>
            <a
              target="_blank"
              href="https://apps.microsoft.com/store/detail/arcsite/9N9HM6J715VP"
              rel="noreferrer"
              className="mt-4"
            >
              <img
                alt="windows"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.windows.logo}
              />
            </a>
          </div>
          <p className="text-12 leading-4 text-center text-black-55 mt-4">
            *
            <a
              href="https://www.arcsite.com/pricing"
              target="_blank"
              className="text-blue-398AE7 underline hover:underline"
              rel="noreferrer"
            >
              Pro and Premium
            </a>{' '}
            features currently require tablet devices.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
