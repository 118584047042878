import { Card, Progress } from 'antd';
import { useSelector } from 'react-redux';

type Task = {
  taskId: string;
  projectName: string;
  drawingName: string;
  progress: string;
};

function Tasks() {
  const tasks = useSelector<any, Task[]>(state => state.archiveTasks);
  if (!tasks.length) {
    return null;
  }

  return (
    <div id="archiving-tasks-app">
      <Card title="Packing Projects" bordered={false}>
        {tasks.map(task => (
          <div key={task.taskId}>
            <div>
              Project:&nbsp;
              {task.projectName}
            </div>
            <div>{task.drawingName ? `Drawing: ${task.drawingName}` : ''}</div>
            <Progress percent={parseInt(task.progress, 10)} />
          </div>
        ))}
      </Card>
    </div>
  );
}

export default Tasks;
