import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { User } from './reducers/product-management';

export function useModalToggle(
  initial = false
): [boolean, React.Dispatch<React.SetStateAction<void>>] {
  const [visible, setVisible] = useState(initial);

  function toggleVisible() {
    setVisible(!visible);
  }

  return [visible, toggleVisible];
}

export function useSearchBox({
  placeholder,
  onSearch,
  className,
  initialSearchKeyword,
}: {
  placeholder: string;
  onSearch: (v: string) => void;
  className?: {
    wrapper: string;
  };
  initialSearchKeyword?: string;
}) {
  const [prevSearch, setPrevSearch] = useState('');
  const [search, setSearch] = useState(initialSearchKeyword || '');

  const handleSearch = (value: string) => {
    // 上次没有搜索的名字，并且这次也没有
    if (!prevSearch && !value) {
      return;
    }
    // 没有改
    if (value == prevSearch) {
      return;
    }
    setPrevSearch(value);
    onSearch(value);
  };

  useEffect(() => {
    if (typeof initialSearchKeyword === 'string')
      setSearch(initialSearchKeyword);
  }, [initialSearchKeyword]);

  const component = (
    <form
      className={`flex items-center ${className?.wrapper || ''}`}
      onSubmit={e => {
        e.preventDefault();
        handleSearch(search);
      }}
    >
      <Input
        className="flex-grow"
        placeholder={placeholder}
        value={search}
        onChange={e => setSearch(e.target.value)}
        suffix={
          <>
            {search ? (
              <CloseCircleFilled
                onClick={() => {
                  setSearch('');
                  // 前一次没有搜索值，不需要重新search
                  if (!prevSearch) {
                    return;
                  }
                  handleSearch('');
                }}
                className="text-dark-gray mr-2"
              />
            ) : null}
            <SearchOutlined onClick={() => handleSearch(search)} />
          </>
        }
      />
    </form>
  );
  return { component, searchInput: search, searchValue: prevSearch, setSearch };
}

export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => any
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

type SetValue<T> = Dispatch<SetStateAction<T>>;
export function useLocalStorage<T>(
  key: string,
  initialValue: T | (() => T)
): [T, SetValue<T>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore: T =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export function useUser() {
  return useSelector<RootState>(state => state.user) as User | null;
}
